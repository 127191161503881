import { selectors } from '../selectors'
import { $elementHasVal, elementExists } from '../shared'
import $ from 'jquery'
import { event } from '../validation/ruleset'
import { input } from '../validation/enums'
import _ from 'lodash'
import { NATIONALITY_ID } from './nationality'

const NATIONALITY = {
    [NATIONALITY_ID.CZECH]: '1',
    [NATIONALITY_ID.SLOVAK]: '2',
    [NATIONALITY_ID.OTHER]: '100',
}

export const init = () => {
    // hideAll() // Just in case, elements should have [style="display: none;"] attr

    initFor(selectors.inputs.nationality) // RegistrationClassic
    // initFor(selectors.inputs.moneySourceComplete) // RegistrationComplete
    // initFor(selectors.inputs.moneySourceClientComplete) // ClientData + ClientComplete
}

const FORM_DATA_ATTR_SELECTOR = '[data-reveal-nationality]'
const FORM_DATA_ATTR = 'data-reveal-nationality'

const initFor = (selector) => {
    if (!elementExists(selector)) {
        return
    }

    const $nationalityInput = $(selector)

    $nationalityInput.on(event[input.select], () => {
        const selectedNationalityId = $nationalityInput.val()
        revealFormSections(selectedNationalityId)
    })

    $elementHasVal($nationalityInput) && $nationalityInput.trigger(event[input.select])
}

// const hideAll = () => $(FORM_DATA_ATTR_SELECTOR).slideUp()
const revealFormSections = (nationalityId) => {
    const nationalityIdent = _.invert(NATIONALITY)[nationalityId]

    $(FORM_DATA_ATTR_SELECTOR).each((_, element) => {
        $(element).attr(FORM_DATA_ATTR).includes(nationalityIdent) ? $(element).show() : $(element).hide()
    })
}
